@import "../../colours";

.tickerShell {
    align-self: stretch;
    display: flex;
    //border: 1px solid $border-color;
    padding: 20px 150px;

    .timeIsUp {
        flex: 1;
        text-align: center;
        font-size: 3.5rem;
        text-transform: uppercase;
        font-weight: 600;
    }
}
@media screen and (max-width: 768px) {
    .tickerShell {
        padding: 0;
        margin: auto 2.5rem;
    }
}
